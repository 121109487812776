import styles from './Form.module.css';
import React, {Component} from "react";
import hausImg from "./haus.png";

class Form extends Component {

    constructor(props) {
        super(props);


        this.canvasRef = React.createRef()

        this.state = {
        }

        this.action = {
        }
    }

    render() {
        return (
            <div className={styles.root}>
                <div className="wrapper" >
                    <div style={{marginLeft: this.props.margin, marginRight: this.props.margin, padding: 20}}>

                        <center>
                            <div className={styles.header2} style={{fontWeight: 'bold'}}>AUSGABEN</div>
                        </center>
                        <table style={{whiteSpace: 'nowrap'}}>
                            <tbody>
                            <tr>
                                <td style={{width: '50%'}}>
                                    <br/>
                                    <div className={styles.label}>Baufinanzierungskosten</div>
                                    <div className={styles.erg}>{this.props.data.inJahren.baufinanzierungskosten.toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</div>
                                    <div className={styles.icon2} style={{textAlign: 'right'}}><i className="fas fa-share fa-rotate-180 fa-flip-vertical"></i></div>
                                </td>
                                <td>
                                    <div className={styles.ergHouse}>
                                        <div style={{fontSize: 'clamp(2px, 2.5vw, 40px)'}}>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <center>
                                                Restdarlehen<br/>
                                                <div>{this.props.data.finanzierung.restdarlehen.toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</div>
                                            </center>
                                        </div>
                                    </div>
                                    <img src={hausImg} className={styles.haus}/>
                                </td>
                                <td style={{textAlign: 'right', width: '50%'}}>
                                    <div className={styles.label}>IMMO SAFE KONZEPT<br/>+ Hausgeld</div>
                                    <div className={styles.erg}>{this.props.data.inJahren.immoSafeKonzept.toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</div>
                                    <div className={styles.icon2} style={{textAlign: 'left'}}><i className="fas fa-share fa-flip-both"></i></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }
}

export default Form;
