import styles from './Form.module.css';
import React, {Component} from "react";
import {InputNumber} from "primereact/inputnumber";

class Form extends Component {

    constructor(props) {
        super(props);


        this.canvasRef = React.createRef()

        this.state = {
        }

        this.action = {
            onChange: (field, value) => {
                this.state[field] = value
                this.forceUpdate()
            }
        }
    }

    render() {
        return (
            <div style={{padding: 20}}>
                <div className="wrapper" >
                    <center>

                        <h2 style={{margin: 0, fontSize: 'clamp(2px, 2.5vw, 35px)'}} className="immoPlanColor1">Berechne deinen Vermögensaufbau mit Kapitalanlage Immobilien</h2>
                        <br/>

                        <table style={{whiteSpace: 'nowrap'}}>
                            <tbody>
                            <tr>
                                <td colSpan={3}><h3 className="immoPlanColor3" style={{margin: 5}}>Finanzierungsübersicht</h3></td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}>Zinsfestschreibung:</td>
                                <td>
                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.finanzierung.zinsfestschreibung}
                                        onValueChange={(e) => {
                                            this.props.data.finanzierung.zinsfestschreibung = e.value
                                            this.props.onDataChange()
                                        }}
                                        min={10}
                                        locale="de-DE" /> Jahre
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}>Kaufpreis:</td>
                                <td>
                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.finanzierung.kaufpreis}
                                        onValueChange={(e) => {
                                            this.props.data.finanzierung.kaufpreis = e.value
                                            this.props.onDataChange()
                                        }}
                                        locale="de-DE" /> €
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}>Werkvertragsanteil:</td>
                                <td>
                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.finanzierung.werkvertrag}
                                        onValueChange={(e) => {
                                            this.props.data.finanzierung.werkvertrag = e.value
                                            this.props.onDataChange()
                                        }}/> €
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}>Kaufnebenkosten:</td>
                                <td>

                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.finanzierung.kaufnebenkosten}
                                        onValueChange={(e) => {
                                            this.props.data.finanzierung.kaufnebenkosten = e.value
                                            this.props.onDataChange()
                                        }}/> €
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}>Eigenkapital:</td>
                                <td>
                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.finanzierung.eigenkapital}
                                        onValueChange={(e) => {
                                            this.props.data.finanzierung.eigenkapital = e.value
                                            this.props.onDataChange()
                                        }}
                                        locale="de-DE" /> €
                                </td>
                            </tr>


                            <tr>
                                <td style={{textAlign: 'right'}}><b>Gesamtfinanzierung:</b></td>
                                <td style={{textAlign: 'right'}}>
                                    <b style={{paddingRight: 45}}> {this.props.data.finanzierung.gesamt.toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</b>
                                </td>
                            </tr>





                            <tr>
                                <td colSpan={2}><div className="immoPlanLine1" style={{paddingTop: 10}}/></td>
                            </tr>
                            <tr>
                                <td colSpan={2}><h3 className="immoPlanColor3" style={{margin: 5}}>Aufwendungen monatlich</h3></td>
                            </tr>

                            <tr>
                                <td style={{textAlign: 'right'}}>Zins:</td>
                                <td>
                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.aufwendungenMonatlich.zinsen}
                                        onValueChange={(e) => {
                                            this.props.data.aufwendungenMonatlich.zinsen = e.value
                                            this.props.onDataChange()
                                        }}
                                        locale="de-DE" /> %
                                    <div style={{textAlign: 'right', paddingRight: 45}}>{this.props.data.aufwendungenMonatlich.zinsenSumme.toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}>Tilgung:</td>
                                <td>
                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.aufwendungenMonatlich.tilgung}
                                        onValueChange={(e) => {
                                            this.props.data.aufwendungenMonatlich.tilgung = e.value
                                            this.props.onDataChange()
                                        }}
                                        locale="de-DE" /> %
                                    <div style={{textAlign: 'right', paddingRight: 45}}>{this.props.data.aufwendungenMonatlich.tilgungSumme.toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}>nicht umlegbare Hausgelder:</td>
                                <td>
                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.aufwendungenMonatlich.nichtUmlegbareHausgelder}
                                        onValueChange={(e) => {
                                            this.props.data.aufwendungenMonatlich.nichtUmlegbareHausgelder = e.value
                                            this.props.onDataChange()
                                        }}
                                        locale="de-DE" /> €
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}>IMMO SAFE KONZEPT:</td>
                                <td>
                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.aufwendungenMonatlich.immoSafeKonzept}
                                        onValueChange={(e) => {
                                            this.props.data.aufwendungenMonatlich.immoSafeKonzept = e.value
                                            this.props.onDataChange()
                                        }}
                                        locale="de-DE" /> €
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}><b>Gesamtaufwendung:</b></td>
                                <td style={{textAlign: 'right'}}>
                                    <b style={{paddingRight: 45}}> {this.props.data.aufwendungenMonatlich.gesamt.toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</b>
                                </td>
                            </tr>






                            <tr>
                                <td colSpan={2}><div className="immoPlanLine1" style={{paddingTop: 10}}/></td>
                            </tr>
                            <tr>
                                <td colSpan={3}><h3 className="immoPlanColor3" style={{margin: 5}}>Einnahme monatlich</h3></td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}>Miete:</td>
                                <td>
                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.einnahmeMonatlich.miete}
                                        onValueChange={(e) => {
                                            this.props.data.einnahmeMonatlich.miete = e.value
                                            this.props.onDataChange()
                                        }}
                                        locale="de-DE" /> €
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}>Mietsubvention:</td>
                                <td>
                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.einnahmeMonatlich.mietsubvention}
                                        onValueChange={(e) => {
                                            this.props.data.einnahmeMonatlich.mietsubvention = e.value
                                            this.props.onDataChange()
                                        }}
                                        locale="de-DE" /> €
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}><b>Gesamteinnahmen:</b></td>
                                <td style={{textAlign: 'right'}}>
                                    <b style={{paddingRight: 45}}> {this.props.data.einnahmeMonatlich.gesamt.toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</b>
                                </td>
                            </tr>





                            <tr>
                                <td colSpan={2}><div className="immoPlanLine1" style={{paddingTop: 10}}/></td>
                            </tr>
                            <tr>
                                <td colSpan={3}><h3 className="immoPlanColor3" style={{margin: 5}}>Einnahme Steuerlich monatlich</h3></td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}>Steuervorteil aus AFA:</td>
                                <td>
                                    <div style={{textAlign: 'right', paddingRight: 45}}>{this.props.data.einnahmeSteuerlich.stuervorteilAusAFA.toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right', paddingTop: 10}}>Steuervorteil aus Werkvertrag:</td>
                                <td style={{paddingTop: 10}}>

                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.einnahmeSteuerlich.steuervortailAusWerkvertragMonate}
                                        onValueChange={(e) => {
                                            this.props.data.einnahmeSteuerlich.steuervortailAusWerkvertragMonate = e.value
                                            this.props.onDataChange()
                                        }}
                                        min={10}
                                        locale="de-DE" /> Monate

                                </td>
                            </tr>

                            <tr>
                                <td style={{textAlign: 'right'}}>Gesamt:</td>
                                <td>
                                    <div style={{textAlign: 'right', paddingRight: 45}}>{this.props.data.einnahmeSteuerlich.steuervortailAusWerkvertrag.toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</div>
                                </td>
                            </tr>


                            <tr>
                                <td style={{textAlign: 'right', paddingTop: 10}}><b>Gesamtsteuereinnahmen:</b></td>
                                <td style={{textAlign: 'right', paddingTop: 10}}>
                                    <b style={{paddingRight: 45}}> {this.props.data.einnahmeSteuerlich.gesamt.toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</b>
                                </td>
                            </tr>






                            <tr>
                                <td colSpan={2}><div className="immoPlanLine1" style={{paddingTop: 10}}/></td>
                            </tr>
                            <tr>
                                <td colSpan={2}><h3 className="immoPlanColor3" style={{margin: 5}}>Wert-/Mietsteigerung</h3></td>
                            </tr>


                            <tr>
                                <td style={{textAlign: 'right'}}>Wertentwicklung:</td>
                                <td>
                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.wertsteigerung.wertentwicklung}
                                        onValueChange={(e) => {
                                            this.props.data.wertsteigerung.wertentwicklung = e.value
                                            this.props.onDataChange()
                                        }}
                                        locale="de-DE" /> %
                                </td>
                            </tr>


                            <tr>
                                <td style={{textAlign: 'right'}}>Mietsteigerung alle 3 Jahre:</td>
                                <td>
                                    <InputNumber
                                        className="p-inputtext-sm"
                                        inputStyle={{textAlign: 'right', width: '100px', padding: 3}}
                                        value={this.props.data.wertsteigerung.mietsteigerungAlle3Jahre}
                                        onValueChange={(e) => {
                                            this.props.data.wertsteigerung.mietsteigerungAlle3Jahre = e.value
                                            this.props.onDataChange()
                                        }}
                                        locale="de-DE" /> %
                                </td>
                            </tr>




                            <tr>
                                <td colSpan={2}><div className="immoPlanLine1" style={{paddingTop: 10}}/></td>
                            </tr>
                            <tr>

                            </tr>
                            <tr>
                                <td>
                                    <h3 className="immoPlanColor3" style={{margin: 5}}>
                                        {this.props.data.eigenaufwandUeberschuss < 0 && "Eigenaufwand"}
                                        {this.props.data.eigenaufwandUeberschuss >= 0 && "Überschuss"} monatlich
                                    </h3>
                                </td>
                                <td style={{textAlign: 'right'}}>

                                    <b>
                                        {this.props.data.eigenaufwandUeberschuss < 0 && <span style={{fontSize: 24, color: '#bd3001', paddingRight: 40}}>{this.props.data.eigenaufwandUeberschuss.toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>}
                                        {this.props.data.eigenaufwandUeberschuss >= 0 && <span style={{fontSize: 24, color: '#1a880d', paddingRight: 40}}>{this.props.data.eigenaufwandUeberschuss.toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>}
                                    </b>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </center>
                </div>
            </div>
        );
    }
}

export default Form;
