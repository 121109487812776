import styles from './Form.module.css';

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'


import {InputNumber} from "primereact/inputnumber";
import React, {Component} from "react";
import DiagonalLine from "./DiagonalLine";

class Form extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    componentDidMount() {
    }

    render() {
        return (
            <div style={{transform: 'scale(' + this.props.scale +')', marginLeft: this.props.margin, marginRight: this.props.margin, padding: 20}}>
                <div className="wrapper" >
                    <table style={{whiteSpace: 'nowrap', width: '100%', borderSpacing: 0}}>
                        <tbody style={{whiteSpace: 'nowrap'}}>
                        <tr>
                            <td colSpan={3}></td>
                            <td colSpan={2}>
                                <div className={styles.label}>Wertentwicklung p.A.</div>
                            </td>
                            <td colSpan={2} style={{textAlign: 'right'}}>
                                <div className={styles.label}>Wertentwicklung insg.</div>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan={6}>
                                <div>
                                    <div className={styles.label}>Kaufpreis</div>
                                    <div className={styles.erg} style={{textAlign: 'right'}}>{this.props.data.finanzierung.kaufpreis.toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</div>

                                    <br/>
                                    <br/>
                                    <div className={styles.label}>Eigenkapital</div>
                                    <div className={styles.erg} style={{textAlign: 'right'}}>{this.props.data.finanzierung.eigenkapital.toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</div>
                                </div>
                            </td>
                            <td rowSpan={5} style={{width: '50%'}}>

                                <div style={{position: 'relative'}}>

                                    <div>
                                        <DiagonalLine alignment= 'bottom' marginTop='-100px' height='100px'/>
                                        <DiagonalLine alignment= 'bottom' marginTop='-99px' height='99px'/>
                                        <DiagonalLine alignment= 'bottom' marginTop='-98px' height='98px'/>
                                    </div>

                                    <div style={{position: 'absolute', width: '100%'}}>
                                        <div className="immoPlanLine1" style={{paddingTop: 0}}/>
                                    </div>

                                    <div>
                                        <DiagonalLine alignment= 'top' marginTop='3px' height='98px'/>
                                        <DiagonalLine alignment= 'top' marginTop='3px' height='99px'/>
                                        <DiagonalLine alignment= 'top' marginTop='3px' height='100px'/>
                                    </div>

                                </div>
                            </td>
                            <td><i className="pi pi-caret-right" style={{paddingTop: 5, marginLeft: -6}}/></td>
                            <td>
                                <div>
                                    <span className={styles.erg}>{this.props.data.wertsteigerung.wertentwicklung}%</span>
                                </div>
                            </td>
                            <td style={{width: '50%'}}><div className="immoPlanLine2" style={{paddingTop: 0}}/></td>
                            <td><i className="pi pi-caret-right" style={{marginLeft: -6, float: 'left'}}/></td>
                            <td style={{textAlign: 'right'}}>
                                <span className={styles.erg}>{this.props.data.finanzierung.wertentwicklung.toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>
                            </td>
                        </tr>


                        <tr>
                            <td/>
                            <td colSpan={2} style={{height: 70, verticalAlign: 'bottom'}}>
                                <div className={styles.label}>Tilgung p.A.</div>
                            </td>
                            <td colSpan={2} style={{height: 70, verticalAlign: 'bottom', textAlign: 'right'}}>
                                <div className={styles.label}>Restdarlehen</div>
                            </td>
                        </tr>
                        <tr>
                            <td><i className="pi pi-caret-right" style={{marginLeft: -6, color: '#215192', verticalAlign: 'middle'}}/></td>
                            <td style={{textAlign: "left"}}>
                                <div>
                                    <div className={styles.erg}>{this.props.data.aufwendungenMonatlich.tilgung}%</div>
                                </div>
                            </td>
                            <td style={{width: '50%'}}><div className="immoPlanLine1"/></td>
                            <td><i className="pi pi-caret-right" style={{marginLeft: -6, color: '#215192', float: "left"}}/></td>
                            <td style={{textAlign: 'right', verticalAlign: 'middle'}}>
                                <span className={styles.erg}>{this.props.data.finanzierung.restdarlehen.toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>
                            </td>
                        </tr>


                        <tr>
                            <td/>
                            <td colSpan={2} style={{height: 70, verticalAlign: 'bottom'}}>
                                <div className={styles.label}>Jahre</div>
                            </td>
                            <td colSpan={2} style={{height: 70, verticalAlign: 'bottom', textAlign: 'right'}}>
                                <div className={styles.label}>Steuerfreier Gewinn</div>
                            </td>
                        </tr>

                        <tr>
                            <td><i className="pi pi-caret-right" style={{paddingTop: 5, marginLeft: -6}}/></td>
                            <td>
                                <div>
                                    <div className={styles.erg}>{this.props.data.finanzierung.zinsfestschreibung}</div>
                                </div>
                            </td>
                            <td style={{width: '50%'}}><div className="immoPlanLine2"/></td>
                            <td><i className="pi pi-caret-right" style={{marginLeft: -6, float: 'left'}}/></td>
                            <td style={{textAlign: 'right'}}>
                                <span className={styles.erg}>{this.props.data.finanzierung.darlehnGewinn.toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Form;
