import './App.css';

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import React, {Component} from "react";

import Step1 from "./step1/Form"
import Step2 from "./step2/Form"
import Step3 from "./step3/Form"
import Step4 from "./step4/Form"
import Step99 from "./step99/Form"

class App extends Component {

    constructor(props) {
        super(props);



        this.state = {
            step: 1,
            width: 0,
            height: 0,
            scale: 1
        }

        this.data = {
            finanzierung: {
                zinsfestschreibung: 10,
                kaufpreis: 200000,
                werkvertrag: -1,
                kaufnebenkosten: 0,
                eigenkapital: 0,
                gesamt: 0,
                wertentwicklung: 0,
                restdarlehen: 0,
                darlehnGewinn: 0
            },
            aufwendungenMonatlich: {
                zinsen: 2,
                zinsenSumme: 2,
                tilgung: 2.5,
                tilgungSumme: 2.5,
                nichtUmlegbareHausgelder: 100,
                immoSafeKonzept: 65,
                finanzierungskosten: 0,
                gesamt: 0
            },
            einnahmeMonatlich: {
                miete: 700,
                mietsubvention: 0,
                gesamt: 0
            },
            einnahmeSteuerlich: {
                stuervorteilAusAFA: 0,
                steuervortailAusWerkvertragMonate: 36,
                steuervortailAusWerkvertrag: 0,
                gesamt: 0
            },
            wertsteigerung: {
                wertentwicklung: 3,
                mietsteigerungAlle3Jahre: 20
            },
            eigenaufwandUeberschuss: 0,
            eigenaufwandUeberschussInJahren: 0,
            inJahren: {
                mieteinnahmen: 0,
                steuervorteil: 0,
                baufinanzierungskosten: 0,
                immoSafeKonzept: 0
            }
        }

        this.action = {
            onDataChange: () => {

                //finanzierung
                if(this.data.finanzierung.werkvertrag == -1) {
                    this.data.finanzierung.werkvertrag = (this.data.finanzierung.kaufpreis/100) * 8
                }
                this.data.finanzierung.kaufnebenkosten = (((this.data.finanzierung.kaufpreis - this.data.finanzierung.werkvertrag)/100) * 7)

                this.data.finanzierung.gesamt = this.data.finanzierung.kaufpreis + this.data.finanzierung.kaufnebenkosten - this.data.finanzierung.eigenkapital

                this.data.finanzierung.wertentwicklung = this.data.finanzierung.kaufpreis
                for (let i = 0; i <= this.data.finanzierung.zinsfestschreibung-1; i++) {
                    this.data.finanzierung.wertentwicklung = (this.data.finanzierung.wertentwicklung/100) * (100+this.data.wertsteigerung.wertentwicklung)
                }




                this.data.finanzierung.restdarlehen = 0
                let zinsfestschreibungMonate = this.data.finanzierung.zinsfestschreibung * 12
                let restdarlehen = this.data.finanzierung.gesamt

                let tilgungSumme = ((this.data.finanzierung.gesamt/100) * (this.data.aufwendungenMonatlich.zinsen + this.data.aufwendungenMonatlich.tilgung)) / 12
                for (let i = 1; i < zinsfestschreibungMonate; i++) {
                    let zinsen = ((restdarlehen/100) * this.data.aufwendungenMonatlich.zinsen) / 12
                    restdarlehen = restdarlehen - tilgungSumme + zinsen
                }
                this.data.finanzierung.restdarlehen = restdarlehen

                this.data.finanzierung.darlehnGewinn = this.data.finanzierung.wertentwicklung - restdarlehen


                //aufwendungenMonatlich

                this.data.aufwendungenMonatlich.zinsenSumme = ((this.data.finanzierung.gesamt/100) * this.data.aufwendungenMonatlich.zinsen) / 12
                this.data.aufwendungenMonatlich.tilgungSumme = ((this.data.finanzierung.gesamt/100) * this.data.aufwendungenMonatlich.tilgung) / 12

                this.data.aufwendungenMonatlich.finanzierungskosten = this.data.aufwendungenMonatlich.zinsenSumme + this.data.aufwendungenMonatlich.tilgungSumme + this.data.aufwendungenMonatlich.nichtUmlegbareHausgelder

                this.data.aufwendungenMonatlich.gesamt = this.data.aufwendungenMonatlich.finanzierungskosten + this.data.aufwendungenMonatlich.immoSafeKonzept


                //einnahmeMonatlich

                this.data.einnahmeMonatlich.gesamt = this.data.einnahmeMonatlich.miete - this.data.einnahmeMonatlich.mietsubvention

                //einnahmeSteuerlich
                let stuervorteilAusAFA = (this.data.finanzierung.kaufpreis/100) * 85
                stuervorteilAusAFA = stuervorteilAusAFA + this.data.finanzierung.kaufnebenkosten
                stuervorteilAusAFA = (stuervorteilAusAFA/100)*2
                stuervorteilAusAFA = (stuervorteilAusAFA/100)*35
                stuervorteilAusAFA = stuervorteilAusAFA/12
                this.data.einnahmeSteuerlich.stuervorteilAusAFA = stuervorteilAusAFA


                let steuervortailAusWerkvertrag = (this.data.finanzierung.werkvertrag/100) * 35
                steuervortailAusWerkvertrag = steuervortailAusWerkvertrag / this.data.einnahmeSteuerlich.steuervortailAusWerkvertragMonate
                this.data.einnahmeSteuerlich.steuervortailAusWerkvertrag = steuervortailAusWerkvertrag

                this.data.einnahmeSteuerlich.gesamt = stuervorteilAusAFA + steuervortailAusWerkvertrag

                //Eigenaufwand/Überschuss
                this.data.eigenaufwandUeberschuss = (this.data.einnahmeMonatlich.gesamt + this.data.einnahmeSteuerlich.gesamt) - this.data.aufwendungenMonatlich.gesamt
                //inJahren

                this.data.inJahren.mieteinnahmen = 0
                let mieteinnahmenImJahr = this.data.einnahmeMonatlich.gesamt * 12
                for (let i = 0; i <= this.data.finanzierung.zinsfestschreibung-1; i++) {

                    if(i != 0 && i % 3 == 0) {
                        let mietsteigerungImJahr = (mieteinnahmenImJahr/100) * (100+this.data.wertsteigerung.mietsteigerungAlle3Jahre)
                        mieteinnahmenImJahr = mietsteigerungImJahr
                    }
                    this.data.inJahren.mieteinnahmen = this.data.inJahren.mieteinnahmen + mieteinnahmenImJahr
                }

                this.data.inJahren.steuervorteil = this.data.einnahmeSteuerlich.gesamt * 12 * this.data.finanzierung.zinsfestschreibung

                this.data.inJahren.baufinanzierungskosten = (this.data.aufwendungenMonatlich.zinsenSumme + this.data.aufwendungenMonatlich.tilgungSumme) * 12 * this.data.finanzierung.zinsfestschreibung

                this.data.inJahren.immoSafeKonzept = (this.data.aufwendungenMonatlich.immoSafeKonzept + this.data.aufwendungenMonatlich.nichtUmlegbareHausgelder) * 12 * this.data.finanzierung.zinsfestschreibung

                this.forceUpdate()
            },
            previousStep: () => {
                if(this.state.step != 1){
                    this.setState({step: this.state.step-1})
                }
            },
            nextStep: () => {
                if(this.state.step != 3){
                    this.setState({step: this.state.step+1})
                }
            }
        }

    }


    componentDidMount() {
        this.action.onDataChange()
        this.updateWindowDimensions();
        window.addEventListener('resize', () => this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.state.width = window.innerWidth
        this.state.height = window.innerHeight
        if(this.state.width < 800) {
            this.state.scale = 0.5
            this.state.margin = '-35%'
        } else if(this.state.width < 1024) {
            this.state.scale = 0.8
            this.state.margin = '0'
        } else {
            this.state.scale = 1
            this.state.margin = '0'
        }
        this.forceUpdate()
    }

    render() {
        console.log(this.props)
        return (
            <div className="root">
                <div style={{pageBreakAfter: 'always'}}>
                    <Step1 data={this.data} scale={this.state.scale} margin={this.state.margin} onDataChange={this.action.onDataChange}/>
                </div>

                <div style={{pageBreakAfter: 'always'}}>
                    <Step2 data={this.data}/>
                    <Step3 data={this.data}/>
                </div>

                <div style={{pageBreakAfter: 'always'}}>
                    <Step4 data={this.data}/>
                </div>
            </div>
        );
    }
}

export default App;
