import styles from './Form.module.css';

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'


import {InputNumber} from "primereact/inputnumber";
import React, {Component} from "react";

class DiagonalLine extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div style={{
                position: 'absolute',
                marginTop: this.props.marginTop,
                height: this.props.height,
                width:'100%',
                background: 'linear-gradient(to ' + this.props.alignment +' right, rgba(255, 255, 255, 0) calc(50% - 1px), #616A6F, rgba(255, 255, 255, 0) calc(50% + 1px) )'}}>
            </div>
        );
    }
}

export default DiagonalLine;
