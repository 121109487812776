import styles from './Form.module.css';

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'


import {InputNumber} from "primereact/inputnumber";
import React, {Component} from "react";
import DiagonalLine from "./DiagonalLine";

class Form extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    componentDidMount() {
    }

    render() {
        return (
            <div style={{marginLeft: this.props.margin, marginRight: this.props.margin, padding: 20}}>
                <div className="wrapper" >
                    <h2 className="immoPlanColor3" style={{textAlign: 'center', fontSize: 'clamp(2px, 2.5vw, 35px)'}}>Deine Kapitalanlage Immobilie  mit unserem IMMOSAFE Konzept</h2>


                    <table style={{whiteSpace: 'nowrap', width: '100%', borderSpacing: 0}}>
                        <tbody style={{whiteSpace: 'nowrap'}}>
                        <tr>
                            <th></th>
                            <th className={styles.header} s>Kaufpreis</th>
                            <th className={styles.header}>
                                {this.props.data.eigenaufwandUeberschuss < 0 && "Eigenaufwand"}
                                {this.props.data.eigenaufwandUeberschuss >= 0 && "Überschuss"} monatlich anfänglich</th>
                            <th className={styles.header}>Wertentwicklung</th>
                            <th className={styles.header}>Steuerfreier Gewinn</th>
                        </tr>
                        <tr>
                            <td><i className="fas fa-home immoPlanColor1"/><i className="fas fa-home" style={{color: '#cecece'}}/><i className="fas fa-home" style={{color: '#cecece'}}/><i className="fas fa-home" style={{color: '#cecece'}}/></td>
                            <td className={styles.ergCell}><span>{this.props.data.finanzierung.kaufpreis.toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span></td>
                            <td className={styles.ergCell}>
                                {this.props.data.eigenaufwandUeberschuss < 0 && <span style={{color: '#bd3001'}}>{this.props.data.eigenaufwandUeberschuss.toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>}
                                {this.props.data.eigenaufwandUeberschuss >= 0 && <span style={{color: '#1a880d'}}>{this.props.data.eigenaufwandUeberschuss.toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>}
                            </td>
                            <td className={styles.ergCell}><span>{this.props.data.finanzierung.wertentwicklung.toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span></td>
                            <td className={styles.ergCell}><span>{this.props.data.finanzierung.darlehnGewinn.toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span></td>
                        </tr>
                        <tr>
                            <td><i className="fas fa-home immoPlanColor1"/><i className="fas fa-home immoPlanColor1"/><i className="fas fa-home" style={{color: '#cecece'}}/><i className="fas fa-home" style={{color: '#cecece'}}/></td>
                            <td className={styles.ergCell}><span>{(this.props.data.finanzierung.kaufpreis * 2).toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span></td>
                            <td className={styles.ergCell}>
                                {this.props.data.eigenaufwandUeberschuss < 0 && <span style={{color: '#bd3001'}}>{(this.props.data.eigenaufwandUeberschuss * 2).toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>}
                                {this.props.data.eigenaufwandUeberschuss >= 0 && <span style={{color: '#1a880d'}}>{(this.props.data.eigenaufwandUeberschuss * 2).toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>}
                            </td>
                            <td className={styles.ergCell}><span>{(this.props.data.finanzierung.wertentwicklung * 2).toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span></td>
                            <td className={styles.ergCell}><span>{(this.props.data.finanzierung.darlehnGewinn * 2).toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span></td>
                        </tr>
                        <tr>
                            <td><i className="fas fa-home immoPlanColor1"/><i className="fas fa-home immoPlanColor1"/><i className="fas fa-home immoPlanColor1"/><i className="fas fa-home" style={{color: '#cecece'}}/></td>
                            <td className={styles.ergCell}><span>{(this.props.data.finanzierung.kaufpreis * 3).toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span></td>
                            <td className={styles.ergCell}>
                                {this.props.data.eigenaufwandUeberschuss < 0 && <span style={{color: '#bd3001'}}>{(this.props.data.eigenaufwandUeberschuss * 3).toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>}
                                {this.props.data.eigenaufwandUeberschuss >= 0 && <span style={{color: '#1a880d'}}>{(this.props.data.eigenaufwandUeberschuss * 3).toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>}
                            </td>
                            <td className={styles.ergCell}><span>{(this.props.data.finanzierung.wertentwicklung * 3).toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span></td>
                            <td className={styles.ergCell}><span>{(this.props.data.finanzierung.darlehnGewinn * 3).toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span></td>
                        </tr>
                        <tr>
                            <td><i className="fas fa-home immoPlanColor1"/><i className="fas fa-home immoPlanColor1"/><i className="fas fa-home immoPlanColor1"/><i className="fas fa-home immoPlanColor1"/></td>
                            <td className={styles.ergCell}><span>{(this.props.data.finanzierung.kaufpreis * 4).toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span></td>
                            <td className={styles.ergCell}>
                                {this.props.data.eigenaufwandUeberschuss < 0 && <span style={{color: '#bd3001'}}>{(this.props.data.eigenaufwandUeberschuss * 4).toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>}
                                {this.props.data.eigenaufwandUeberschuss >= 0 && <span style={{color: '#1a880d'}}>{(this.props.data.eigenaufwandUeberschuss * 4).toLocaleString('de', {useGrouping:true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span>}
                            </td>
                            <td className={styles.ergCell}><span>{(this.props.data.finanzierung.wertentwicklung * 4).toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span></td>
                            <td className={styles.ergCell}><span>{(this.props.data.finanzierung.darlehnGewinn * 4).toLocaleString('de', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})} €</span></td>
                        </tr>
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        );
    }
}

export default Form;
